import React, { useContext } from 'react';
import { createPrebuiltEmailInstall, getPrebuilts } from '../../../shared/common.api';
import moment from 'moment';
import { Calendar30DaysIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, DefaultIntegrationIcon, DownwardArrowIcon, SearchIcon, SortByAlphaIcon, UpwardArrowIcon } from 'components/mui';
import { Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Paper, Popper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import prebuiltEmailPng from '../../../assets/img/Prebuilt_Email.png';
import { InboxMoveIcon } from 'components/mui';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { UserStateContext } from 'context/user-state-context';
import { withStyles } from '@mui/styles';
import { PageHeaderMui } from 'components';
import { history } from '../../../root.component';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { PrebuiltInstallsNavigationTabs } from './installs-navigationTabs.component';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: `#F3F3F4`,
      borderRadius: `14px`,
      '& fieldset': {}
    }
  }
})(TextField);
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
const sortByMenu = [{
  id: 'title',
  name: 'Name',
  icon: <SortByAlphaIcon />
}, {
  id: 'released_when',
  name: 'Released Date',
  icon: <Calendar30DaysIcon />
}];
export const PrebuiltEmailInstallVersion = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [availablePrebuilts, setAvailablePrebuilts] = React.useState([]);
  const [selectedPrebuilt, setSelectedPrebuilt] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [tagPickerOpen, setTagPickerOpen] = React.useState(false);
  const [tagPickerAnchorEl, setTagPickerAnchorEl] = React.useState(null);
  const [tagSelectAll, setTagSelectAll] = React.useState(false);
  const [tagOnlyHover, setTagOnlyHover] = React.useState(null);
  const [sortByAnchorEl, setSortByAnchorEl] = React.useState(null);
  const sortByOpen = Boolean(sortByAnchorEl);
  const [sortKey, setSortKey] = React.useState('title');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [newInstallLoading, setNewInstallLoading] = React.useState(false);
  React.useEffect(() => {
    setIsLoading(true);
    getPrebuilts({
      type_in: 'email',
      company: asCompany.id,
      ...filters,
      limit: 100,
      ordering: sortDirection == 'asc' ? sortKey : '-' + sortKey
    }).then(available => {
      setAvailablePrebuilts(available.data.results);

      // add tags to a list
      if (tags?.length == 0) {
        const tags = [];
        available.data.results.map(prebuilt => {
          prebuilt?.tags?.map(tag => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        });
        setTags(tags.sort());
      }
      setIsLoading(false);
    });
  }, [filters, sortKey, sortDirection]);
  React.useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        search: search
      });
      setSelectedPrebuilt(null);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const handleTagPickerClose = () => {
    setTagPickerOpen(false);
    setTagPickerAnchorEl(null);
  };
  const handleTagPickerClick = e => {
    if (isLoading) {
      return;
    }
    setTagPickerAnchorEl(e.currentTarget);
    setTagPickerOpen(true);
  };
  const handleTagPickerMenuClick = tag => {
    if (filters['tags']?.some(t => t == tag)) {
      //removing
      const t = filters['tags']?.filter(items => items != tag);
      handleSelectedTags(t);
      setTagSelectAll(false);
    } else {
      if (filters['tags']?.length + 1 === tags?.length) {
        setTagSelectAll(true);
      }
      if (!filters['tags']) {
        handleSelectedTags([tag]);
      } else {
        handleSelectedTags([...filters['tags'], tag]);
      }
    }
  };
  const handleTagSelectAll = () => {
    if (tagSelectAll) {
      handleSelectedTags([]);
    } else {
      handleSelectedTags(tags.map(t => t));
    }
    setTagSelectAll(!tagSelectAll);
  };
  const handleSelectedTags = newTags => {
    const new_filters = {
      ...filters
    };
    if (newTags.length == 0) {
      delete new_filters['tags'];
    }
    new_filters['tags'] = newTags;
    setFilters(new_filters);
  };
  const handleSortByClose = () => {
    setSortByAnchorEl(null);
  };
  const handleSortByClick = event => {
    if (isLoading) {
      return;
    }
    setSortByAnchorEl(sortByOpen ? null : event.currentTarget);
  };
  const swapSortDirection = () => {
    if (isLoading) {
      return;
    }
    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc');
  };
  const calcMaxWidth = () => {
    if (1600 * 100 / window.innerWidth > 95) {
      return '95vw';
    }
    return '1000px';
  };
  const handleInstall = prebuilt => {
    setNewInstallLoading(true);
    setSelectedPrebuilt(null);
    createPrebuiltEmailInstall(prebuilt.version, {
      company: asCompany.id
    }).then(response => {
      history.push(`/templates/edit/email/${response.data.email}`);
    }).catch(error => {
      snackbarService.popup({
        type: 'error',
        message: `Error: ${error}`
      });
      setNewInstallLoading(false);
    });
  };
  return <>
      <div className="wrapper" style={{
      height: !!isLoading.length ? '100vh' : 'auto'
    }}>
        <PageHeaderMui type="Emails" icon={<InboxMoveIcon />} />

        <Stack spacing="20px" sx={{
        p: '20px'
      }}>
          <Box sx={{
          width: '100%'
        }}>
            <PrebuiltInstallsNavigationTabs currentTab="email" />
          </Box>

          <Paper sx={{
          borderRadius: '14px',
          maxWidth: '100vw'
        }}>
            {newInstallLoading ? <Stack sx={{
            p: '20px',
            height: '60vh'
          }} spacing="30px">
                <Box>
                  <Typography sx={{
                fontFamily: 'Nexa',
                fontSize: 24,
                color: '#1D252D'
              }}>Creating Prebuilt Email... </Typography>
                </Box>
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Skeleton variant="rounded" width={610} height={20} />
                </Box>
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Skeleton variant="rounded" width={410} height={20} />
                </Box>
                <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Skeleton variant="rounded" width={210} height={20} />
                </Box>

                <Box sx={{
              width: '100%'
              // height: '100%',
            }}>
                  <Box sx={{
                width: '100%',
                // height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                    <CircularProgress />
                  </Box>
                </Box>
                <Stack direction="row" spacing="24px">
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <Skeleton variant="rounded" width={210} height={120} />
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <Skeleton variant="rounded" width={210} height={120} />
                  </Box>
                </Stack>
              </Stack> : <Stack sx={{
            pb: '10px'
          }}>
                <Stack>
                  <Stack spacing="20px" sx={{
                backgroundColor: '#fff',
                width: '100%',
                py: '10px',
                px: '20px',
                borderRadius: '14px'
              }}>
                    <Stack spacing={'10px'}>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontSize: '20px',
                      lineHeight: '26px'
                    }}>Select a prebuilt email</Typography>
                        <Box>
                          <IconButton onClick={e => {
                        history.push('/templates');
                      }}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Divider />
                    </Stack>
                    <Box sx={{
                  width: '100%'
                }} display="flex" flexDirection="row" justifyContent="space-between">
                      <Box sx={{
                    width: '40%',
                    maxWidth: '400px'
                  }}>
                        <CustomTextField variant="outlined" sx={{
                      width: '100%'
                    }} placeholder="Search" autoComplete="new-password" InputProps={{
                      startAdornment: <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>,
                      endAdornment: search != '' && !!search && <InputAdornment onClick={e => {
                        setSearch('');
                      }} position="start" sx={{
                        cursor: 'pointer'
                      }}>
                                <CloseIcon />
                              </InputAdornment>
                    }} value={search || ''} onChange={e => {
                      setSearch(e.target.value);
                    }} />
                      </Box>

                      <Stack sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }} direction="row" spacing={'12px'}>
                        <Box sx={{
                      height: '40px',
                      width: '40px'
                    }}>
                          <IconButton onClick={e => swapSortDirection()}>
                            {sortDirection == 'asc' ? <UpwardArrowIcon /> : <DownwardArrowIcon />}
                          </IconButton>
                        </Box>
                        <Tooltip title={sortByOpen ? '' : 'Sort by'}>
                          <Box display="flex" flexDirection="row" justifyContent="space-between" onClick={e => {
                        handleSortByClick(e);
                      }} sx={{
                        '&:hover': {
                          backgroundColor: '#D0D9E6'
                        },
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderRadius: '30px',
                        padding: '10px',
                        minWidth: '150px'
                      }}>
                            <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pl: '8px'
                        }}>
                              <Typography sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                {sortByMenu.find(item => item.id == sortKey)?.name}
                              </Typography>
                            </Box>
                            {sortByOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
                          </Box>
                        </Tooltip>
                      </Stack>
                    </Box>
                    <Stack direction="row">
                      <Box onClick={e => {
                    handleTagPickerClick(e);
                  }} className="d-flex h-100 justify-content-between" sx={{
                    borderRadius: '8px',
                    py: '2px',
                    pl: '9px',
                    pr: '2px',
                    cursor: 'pointer',
                    border: '1px solid #E3E5E7',
                    display: 'flex',
                    alignItems: 'center',
                    background: !filters['tags'] || filters['tags']?.length == 0 ? '#fff' : '#C2E7FF',
                    width: '175px'
                  }} direction="row">
                        {(!filters['tags'] || filters['tags']?.length == 0) && <Box className="d-flex w-100 justify-content-between">
                            <Stack spacing="5px" direction="row" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: '7px'
                      }}>
                              <Typography>Tags</Typography>
                            </Stack>
                          </Box>}
                        {filters['tags']?.length > 0 && <Box className="d-flex w-100 justify-content-between">
                            <Stack spacing="5px" direction="row" sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                              <Typography sx={{
                          color: '#3898D9'
                        }}>{'Tags (' + filters['tags']?.length + ')'}</Typography>
                            </Stack>
                            {filters['tags']?.length > 0 && <IconButton size="small" onClick={e => {
                        e.stopPropagation();
                        handleSelectedTags([]);
                        handleTagPickerClose();
                      }}>
                                <CloseIcon size="sm" fill="#3898D9" />
                              </IconButton>}
                          </Box>}
                        {(!filters['tags'] || filters['tags']?.length == 0) && <> {tagPickerOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</>}
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack spacing={'20px'}>
                    {isLoading ? <Grid sx={{
                  px: '8px'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                        {Array(3).fill(null).map((row, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                              <Box sx={{
                      p: '20px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #E8EAED',
                      borderRadius: '8px',
                      width: '315px',
                      backgroundColor: '#fff'
                    }}>
                                <Stack direction="row" spacing={'16px'}>
                                  <Box>
                                    <DefaultIntegrationIcon />
                                  </Box>
                                  <Box sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                    <Skeleton variant="rounded" width={210} height={20} />
                                  </Box>
                                </Stack>
                              </Box>
                            </Grid>)}
                      </Grid> : <Grid sx={{
                  px: '8px',
                  width: '100%'
                }} container spacing={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                        {availablePrebuilts.length > 0 ? <>
                            {availablePrebuilts.map(prebuilt => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={prebuilt?.id}>
                                <Box sx={{
                        px: '20px',
                        pt: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        border: '1px solid #E8EAED',
                        borderRadius: '8px',
                        width: '315px',
                        height: '322px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F3F3F4',
                          boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                        }
                      }} onClick={() => {
                        setSelectedPrebuilt(prebuilt);
                      }}>
                                  <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Stack spacing="8px">
                                      <Box>
                                        <Typography sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                height: '18px'
                              }}>
                                          {prebuilt?.title}
                                        </Typography>
                                      </Box>
                                      {prebuilt?.subtitle ? <Box>
                                          <Typography sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                opacity: 0.8,
                                fontSize: '14px'
                              }}>
                                            {prebuilt?.subtitle}
                                          </Typography>
                                        </Box> : <Box>
                                          <Typography sx={{
                                opacity: 0
                              }}>No subtitle</Typography>
                                        </Box>}
                                    </Stack>
                                    {prebuilt?.thumbnail ? <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                                        <img src={prebuilt?.thumbnail} style={{
                              maxHeight: '156px',
                              maxWidth: '240px'
                            }} alt="prebuilt" />
                                      </Box> : <Box sx={{
                            width: '260px',
                            height: '156px'
                          }}>
                                        <Box sx={{
                              display: 'flex',
                              alignContent: 'center',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%'
                            }}>
                                          <img src={prebuiltEmailPng} height="156" width="260" alt="prebuilt" />
                                        </Box>
                                      </Box>}
                                    {prebuilt?.tags?.length > 0 ? <Box sx={{
                            pb: '6px'
                          }}>
                                        <Stack sx={{
                              pb: '8px',
                              width: '260px',
                              overflowX: 'auto',
                              overFlowY: 'hidden',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em'
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                                marginTop: '25px',
                                marginBottom: '25px'
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888'
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555'
                              }
                            }} direction="row" spacing={'16px'}>
                                          {prebuilt?.tags?.map((tag, index) => <Chip sx={{
                                background: '#C2E7FF'
                              }} key={index} label={tag} />)}
                                        </Stack>
                                      </Box> : <Box sx={{
                            pb: '37px',
                            width: '260px'
                          }}>
                                        <Typography sx={{
                              opacity: 0
                            }}>No tags</Typography>
                                      </Box>}
                                  </Box>
                                </Box>
                              </Grid>)}
                          </> : <>
                            {Object.keys(filters).length != 0 ? <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: '24px'
                    }}>
                                <Typography>No search results. Try adjusting your search or filters.</Typography>
                              </Box> : <Box sx={{
                      px: '10px',
                      width: '100%'
                    }}>
                                <Box sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '14px',
                        width: '100%'
                      }}>
                                  <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '32px',
                          justifyContent: 'center'
                        }}>
                                    <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '300px',
                            lineHeight: '17px'
                          }}>
                                      There are no available prebuilt emails for you to install
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>}
                          </>}
                      </Grid>}
                  </Stack>
                </Stack>
              </Stack>}
          </Paper>
        </Stack>
      </div>

      <Popper anchorEl={tagPickerAnchorEl} open={tagPickerOpen} sx={{
      zIndex: 1500,
      width: '312px'
    }} onClose={handleTagPickerClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleTagPickerClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography variant="tableHeader">Tags</Typography>
              <Box sx={{
              cursor: 'pointer'
            }} onClick={e => {
              handleTagPickerClose();
            }}>
                <CloseIcon />
              </Box>
            </Box>

            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography>Select all</Typography>
              <AntSwitch sx={{
              ml: '40px'
            }} checked={tagSelectAll} onChange={() => {
              handleTagSelectAll();
            }} />
            </Box>
            <Box sx={{
            maxHeight: '50vh',
            overflowY: 'auto'
          }}>
              {tags?.length > 0 ? <Box sx={{
              pl: '25px',
              pb: '25px'
            }}>
                  {tags.map((tag, index) => <Box key={index} onMouseEnter={e => setTagOnlyHover(tag)} onMouseLeave={e => setTagOnlyHover(null)} className="d-flex w-100 justify-content-between">
                      <Stack direction="row" sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="4px">
                        <Checkbox color="primary" checked={!!filters['tags']?.find(t => t == tag)} onChange={e => {
                    handleTagPickerMenuClick(tag);
                  }} sx={{
                    color: '#1D252D',
                    '&.Mui-checked': {
                      color: '#53A6D6'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 22
                    }
                  }} />
                        <Stack direction="row" sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }} spacing="12px">
                          <Typography> {tag}</Typography>
                        </Stack>
                      </Stack>
                      {tagOnlyHover == tag && <Box onClick={e => {
                  handleSelectedTags([tag]);
                }} sx={{
                  pr: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                          <Typography sx={{
                    color: '#3898D9'
                  }}> Only</Typography>
                        </Box>}
                    </Box>)}
                </Box> : <Box sx={{
              pl: '25px',
              pb: '25px'
            }}>
                  <Typography>No tags found</Typography>
                </Box>}
            </Box>
            <Divider />
            <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pr: '25px',
            py: '10px'
          }}>
              <Button onClick={e => {
              handleSelectedTags([]);
            }}>
                <Typography sx={{
                color: '#3898D9',
                textTransform: 'none',
                cursor: 'pointer'
              }}> Clear</Typography>
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={sortByAnchorEl} sx={{
      zIndex: 2000
    }} open={sortByOpen} onClose={handleSortByClose} placement="bottom-start">
        <ClickAwayListener onClickAway={handleSortByClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Stack sx={{
            py: '10px'
          }}>
              {sortByMenu.map((item, index) => <Stack onClick={() => {
              setSortKey(item.id);
              setSortDirection('asc');
              handleSortByClose();
            }} key={index} direction={'row'} spacing={'12px'} sx={{
              '&:hover': {
                backgroundColor: sortKey == item.id ? '#3898D933' : '#F3F3F4'
              },
              cursor: 'pointer',
              paddingX: '15px',
              paddingY: '10px',
              backgroundColor: sortKey == item.id ? '#3898D933' : 'none'
            }}>
                  {sortKey == item.id ? <Box>{sortDirection == 'asc' ? <UpwardArrowIcon fill="#3898D9" /> : <DownwardArrowIcon fill="#3898D9" />}</Box> : <Box sx={{
                px: '12px'
              }} />}
                  <Box>{item.icon}</Box>
                  <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}>{item.name}</Typography>
                </Stack>)}
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '80vh',
        minHeight: '55vh',
        maxWidth: calcMaxWidth(),
        minWidth: calcMaxWidth()
      }
    }} open={!!selectedPrebuilt} onClose={() => {
      setSelectedPrebuilt(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing="8px" sx={{
          p: '4px'
        }}>
            <Stack spacing={'8px'}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '500',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    {selectedPrebuilt?.title}
                  </Typography>
                </Box>
                <IconButton onClick={() => {
                setSelectedPrebuilt(null);
              }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {selectedPrebuilt?.subtitle && <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                  <Typography sx={{
                fontWeight: '300',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                opacity: 0.8
              }} component="span">
                    {selectedPrebuilt?.subtitle}
                  </Typography>
                </Box>}
            </Stack>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1,
          flexGrow: 1
        }}>
            <Stack direction="row" sx={{
            pb: '12px'
          }}>
              <Box sx={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center'
            }}>
                <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                  {selectedPrebuilt?.thumbnail || selectedPrebuilt?.thumbnail_secondary ? <Box sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                      <CustomTooltip placement="right" title={<img src={selectedPrebuilt?.thumbnail_secondary || selectedPrebuilt?.thumbnail} style={{
                    maxHeight: '75vh',
                    maxWidth: '45vw'
                  }} />}>
                        <img src={selectedPrebuilt?.thumbnail_secondary || selectedPrebuilt?.thumbnail} style={{
                      maxHeight: '250px',
                      maxWidth: '400px'
                    }} alt="prebuilt" />
                      </CustomTooltip>
                    </Box> : <Box sx={{
                  width: '400px',
                  height: '250px'
                }}>
                      <Box sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}>
                        <img src={prebuiltEmailPng} height="240" width="400" alt="prebuilt" />
                      </Box>
                    </Box>}
                </Box>
              </Box>
              <Box sx={{
              width: '50%',
              minHeight: '250px',
              maxHeight: '280px',
              pl: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
                <Stack spacing="8px">
                  <Stack direction="row" spacing="4px">
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '700'
                  }}> Version:</Typography>
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '300'
                  }}>{selectedPrebuilt?.version_number}</Typography>
                  </Stack>
                  <Stack direction="row" spacing="4px">
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '700'
                  }}> Created by:</Typography>
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '300'
                  }}>{selectedPrebuilt?.created_by_user?.first_name}</Typography>
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '300'
                  }}>{selectedPrebuilt?.created_by_user?.last_name}</Typography>
                  </Stack>
                  <Stack direction="row" spacing="4px">
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '700'
                  }}> Released:</Typography>
                    <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '300'
                  }}>
                      {moment(selectedPrebuilt?.released_when).format('MMMM DD, YYYY')}
                    </Typography>
                  </Stack>
                  {!(selectedPrebuilt?.available_reason.length == 1 && selectedPrebuilt?.available_reason[0] == 'All') && <Stack direction="row" spacing="4px">
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'start',
                    pt: '10px'
                  }}>
                        <Typography sx={{
                      fontSize: '14px',
                      fontWeight: '700'
                    }}> Availability:</Typography>
                      </Box>

                      <Grid sx={{
                    px: '8px',
                    width: '100%'
                  }} container spacing={{
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1
                  }} columns={{
                    xs: 4,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }}>
                        {selectedPrebuilt?.available_reason?.map((reason, index) => <>
                            {reason != 'All' && <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                                <Box>
                                  <Chip sx={{
                            background: '#C2E7FF'
                          }} label={reason} />
                                </Box>
                              </Grid>}
                          </>)}
                      </Grid>
                    </Stack>}
                </Stack>

                {selectedPrebuilt?.tags?.length > 0 && <Stack direction="row" spacing="8px">
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'start',
                  pt: '10px'
                }}>
                      <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '700'
                  }}> Tags:</Typography>
                    </Box>

                    <Grid sx={{
                  px: '8px',
                  width: '100%'
                }} container spacing={{
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1
                }} columns={{
                  xs: 4,
                  sm: 12,
                  md: 12,
                  lg: 12
                }}>
                      {selectedPrebuilt?.tags.map((tag, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                          <Box>
                            <Chip sx={{
                        background: '#C2E7FF'
                      }} label={tag} />
                          </Box>
                        </Grid>)}
                    </Grid>
                  </Stack>}
              </Box>
            </Stack>

            <Stack spacing="8px">
              {selectedPrebuilt?.description && <Divider />}
              <Stack sx={{
              py: '20px'
            }}>
                {selectedPrebuilt?.description && <Stack spacing="16px">
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '700'
                  }}> Description:</Typography>
                    </Box>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <div dangerouslySetInnerHTML={{
                    __html: selectedPrebuilt?.description
                  }} />
                    </Box>
                  </Stack>}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        p: '20px'
      }}>
          <Stack spacing="16px" direction={'row'}>
            <Button sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} onClick={e => {
            setSelectedPrebuilt(null);
          }} variant="contained">
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={e => {
            handleInstall(selectedPrebuilt);
          }} variant="contained" color="primary" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Install</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};